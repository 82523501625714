import { success, error, abort } from 'redux-saga-requests';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  ids: {},
  meta: {},
  fetching: {},
  error: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DYNAMIC_PAGE_REQUEST:
      if (action.meta.onlyHeader) return state;

      const currentId = state.ids[action.meta.pageId]
        ? state.ids[action.meta.pageId]
        : {};
      const currentIdConfig = currentId.config || {};
      const config = currentIdConfig.sessionId
        ? { ...currentIdConfig, sessionId: null }
        : currentIdConfig;

      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...currentId,
            id: action.meta.pageId,
            config
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            id: action.meta.pageId,
            loading: true,
            aborted: false,
            error: false
          }
        }
      };
    case success(actionTypes.REPORTS_FETCH_METADATA):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            reportMetadata: action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            reportCode: action.meta.reportCode,
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    //page ID based
    case success(actionTypes.FETCH_DYNAMIC_PAGE_REQUEST):
      if (action.meta.onlyHeader) return state;

      const {
        reportMetadata,
        reportPreview,
        ...currentObjectState
      } = state.ids[action.meta.pageId];
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            // ...state.ids[action.meta.pageId],
            ...currentObjectState,
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            loading: false,
            aborted: false,
            error: false
          }
        }
      };
    case success(actionTypes.REPORTS_FETCH_FOLDERS):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            folders: [...action.data]
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    case success(actionTypes.REPORTS_FETCH_REPORTS):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            data: action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    case success(actionTypes.FLEET_PERMISSION_DICTIONARIES):
    case success(actionTypes.FLEET_FETCH_ALL):
    case success(actionTypes.HISTORY_FETCH_OBJECT_CHANGES):
    case success(actionTypes.REPORTS_EDITOR_FETCH_CONFIG):
    case success(actionTypes.DOCUMENTS_FETCH):
    case success(actionTypes.JOURNAL_FETCH_DOCUMENTS):
    case success(actionTypes.JOURNAL_FETCH):
    case success(actionTypes.TABLE_RECORDS_FETCH_REQUEST):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    case success(actionTypes.FORMENGINE_WORKFLOW_UPDATE_MAIN_FORM):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            config: {
              ...state.ids[action.meta.pageId].config,
              ...action.data.config
            }
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };

    case success(actionTypes.JOURNAL_NEW_ENTRY):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    case actionTypes.REPORTS_PREVIEW:
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId]
            // reportPreview: []
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: true,
              aborted: false,
              error: false
            }
          }
        }
      };

    case success(actionTypes.REPORTS_PREVIEW):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            reportPreview: action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: false
            }
          }
        }
      };
    case actionTypes.FLEET_PERMISSION_DICTIONARIES:
    case actionTypes.FLEET_FETCH_ALL:
    case actionTypes.REPORTS_FETCH_METADATA:
    case actionTypes.REPORTS_FETCH_FOLDERS:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: true,
              aborted: false,
              error: false
            }
          }
        }
      };
    case actionTypes.REPORTS_FETCH_REPORTS:
    case actionTypes.HISTORY_FETCH_OBJECT_CHANGES:
    case actionTypes.REPORTS_EDITOR_FETCH_CONFIG:
    case actionTypes.DOCUMENTS_FETCH:
    case actionTypes.JOURNAL_FETCH_DOCUMENTS:
    case actionTypes.JOURNAL_FETCH:
    case actionTypes.JOURNAL_NEW_ENTRY:
    case actionTypes.TABLE_RECORDS_FETCH_REQUEST:
    case actionTypes.FORMENGINE_WORKFLOW_UPDATE_MAIN_FORM: {
      const changedData = {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: true,
              aborted: false,
              error: false
            }
          }
        }
      };

      if (action.meta.topFilterData) {
        changedData.ids = {
          ...state.ids,
          [action.meta.pageId]: {
            ...state.ids[action.meta.pageId],
            topFilterData: action.meta.topFilterData
          }
        };
      }

      return changedData;
    }
    case error(actionTypes.REPORTS_FETCH_REPORTS):
    case error(actionTypes.HISTORY_FETCH_OBJECT_CHANGES):
    case error(actionTypes.REPORTS_EDITOR_FETCH_CONFIG):
    case error(actionTypes.REPORTS_PREVIEW):
    case error(actionTypes.DOCUMENTS_FETCH):
    case error(actionTypes.JOURNAL_FETCH_DOCUMENTS):
    case error(actionTypes.JOURNAL_FETCH):
    case error(actionTypes.JOURNAL_NEW_ENTRY):
    case error(actionTypes.TABLE_RECORDS_FETCH_REQUEST):
    case error(actionTypes.FORMENGINE_WORKFLOW_UPDATE_MAIN_FORM):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: false,
              error: true
            }
          }
        }
      };
    case abort(actionTypes.REPORTS_FETCH_REPORTS):
    case abort(actionTypes.HISTORY_FETCH_OBJECT_CHANGES):
    case abort(actionTypes.REPORTS_EDITOR_FETCH_CONFIG):
    case abort(actionTypes.DOCUMENTS_FETCH):
    case abort(actionTypes.JOURNAL_FETCH_DOCUMENTS):
    case abort(actionTypes.JOURNAL_FETCH):
    case abort(actionTypes.JOURNAL_NEW_ENTRY):
    case abort(actionTypes.TABLE_RECORDS_FETCH_REQUEST):
    case abort(actionTypes.REPORTS_PREVIEW):
    case abort(actionTypes.FORMENGINE_WORKFLOW_UPDATE_MAIN_FORM):
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            [action.meta.type]: {
              loading: false,
              aborted: true,
              error: false
            }
          }
        }
      };
    // object ID based
    case success(actionTypes.DOCUMENTS_BY_OBJECT_ID_FETCH):
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.objectId]: {
            ...state.ids[action.meta.objectId],
            ...action.data
          }
        },
        meta: {
          ...state.meta,
          [action.meta.objectId]: {
            ...state.meta[action.meta.objectId],
            loading: false,
            aborted: false,
            error: false
          }
        }
      };
    case actionTypes.FORMENGINE_WORKFLOW_RELOAD:
      return {
        ...state,
        ids: {
          ...state.ids,
          [action.meta.objectId]: {
            ...state.ids[action.meta.objectId],
            config: {
              ...state.ids[action.meta.objectId].config,
              ...action.data
            }
          }
        }
        // meta: {
        //   ...state.meta,
        //   [action.meta.objectId]: {
        //     ...state.meta[action.meta.objectId],
        //     loading: false,
        //     aborted: false,
        //     error: false
        //   }
        // }
      };
    case error(actionTypes.FLEET_PERMISSION_DICTIONARIES):
    case error(actionTypes.FLEET_FETCH_ALL):
    case error(actionTypes.REPORTS_FETCH_FOLDERS):
    case error(actionTypes.REPORTS_FETCH_METADATA):
    case error(actionTypes.FETCH_DYNAMIC_PAGE_REQUEST):
      if (action.meta.onlyHeader) return state;

      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            loading: false,
            error: true,
            aborted: false,
            errorDetails: action.data
          }
        }
      };
    case abort(actionTypes.FLEET_PERMISSION_DICTIONARIES):
    case abort(actionTypes.FLEET_FETCH_ALL):
    case abort(actionTypes.REPORTS_FETCH_FOLDERS):
    case abort(actionTypes.REPORTS_FETCH_METADATA):
    case abort(actionTypes.FETCH_DYNAMIC_PAGE_REQUEST):
      if (action.meta.onlyHeader) return state;

      return {
        ...state,
        meta: {
          ...state.meta,
          [action.meta.pageId]: {
            ...state.meta[action.meta.pageId],
            loading: false,
            error: false,
            aborted: true
          }
        }
      };
    case actionTypes.CLEAR_DYNAMIC_SESSION:
      if (state.ids[action.meta.pageId]) {
        let config = { ...state.ids[action.meta.pageId].config };
        if (config.formConfig) {
          config.formConfig = { ...config.formConfig, sessionId: null };
          // config.formConfig = null;
          // const { [action.meta.pageId]: removed, ...ids } = state.ids;
          // return {
          //   ...state,
          //   ids
          // };
          // const { formConfig, ...newConfig } = config;
          // config = newConfig;
        } else {
          config.sessionId = null;
        }

        return {
          ...state,
          ids: {
            ...state.ids,
            [action.meta.pageId]: {
              ...state.ids[action.meta.pageId],
              config: config
            }
          }
        };
      }
      return state;
    case actionTypes.CLEAR_DYNAMIC_REDIRECT: {
      const pageId = action.meta.pageId.slice(0, 36);
      if (state.ids[pageId]) {
        let config = { ...state.ids[pageId].config };
        config.data = {};
        if (config.headingConfig) config.headingConfig.data = {};

        return {
          ...state,
          ids: {
            ...state.ids,
            [pageId]: {
              ...state.ids[pageId],
              config: config
            }
          }
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
