import * as actionTypes from '../actions/actionTypes';

export const fetchAllStories = (meta) => {
  return {
    type: actionTypes.FETCH_STORIES,
    request: {
      url: `/stories/all`,
      method: 'get'
    },
    meta: {
      meta
    }
  };
};

export const triggerStoriesDownload = (group) => {
  return {
    type: actionTypes.STORIES_DOWNLOAD,
    request: {
      url: `/stories/downloadasync/${group}`,
      method: 'get'
    },
    meta: {
      group,
      asPromise: true
    }
  };
};

export const triggerPostBacklogDownload = (group) => {
  return {
    type: actionTypes.POST_BACKLOG_DOWNLOAD,
    request: {
      url: `/posts/backlog/${group}`,
      method: 'get'
    },
    meta: {
      group,
      asPromise: true
    }
  };
};

export const getUserProfiles = (group = '') => {
  return {
    type: actionTypes.PROFILES_SUMMARY,
    request: {
      url: `/profiles/summary/${group}`,
      method: 'get'
    },
    meta: {
      group,
      asPromise: true
    }
  };
};

export const getUserProfile = (user = '') => {
  return {
    type: actionTypes.PROFILES_SUMMARY,
    request: {
      url: `/profiles/user/${user}`,
      method: 'get'
    },
    meta: {
      user,
      asPromise: true
    }
  };
};

export const fetchReservedStories = (meta) => {
  return {
    type: actionTypes.FETCH_STORIES,
    request: {
      url: `/stories/reserved`,
      method: 'get'
    },
    meta: {
      meta
    }
  };
};

export const fetchStoriesSummary = (groupName = '') => {
  return {
    type: actionTypes.FETCH_STORIES_SUMMARY,
    request: {
      url: `/stories/summary/${groupName}`,
      method: 'get'
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};

export const fetchStoriesByUsername = (username = '', filter = '') => {
  return {
    type: actionTypes.FETCH_STORIES_BY_USER,
    request: {
      url: `/stories/byUser/${username}/${filter}`,
      method: 'get'
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};
export const fetchStoriesByMonth = (month, groupName = '', filter = '') => {
  let url = `/stories/byMonth/${month}/${groupName}`;
  if (filter === false) url = `/stories/byMonth/${month}/${groupName}/false`;
  return {
    type: actionTypes.FETCH_STORIES_BY_MONTH,
    request: {
      url,
      method: 'get'
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};

export const fetchPostsSummary = (groupName = '') => {
  return {
    type: actionTypes.FETCH_POSTS_SUMMARY,
    request: {
      url: `/posts/summary/${groupName}`,
      method: 'get'
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};

export const fetchPostsByUsername = (username = '', filter = '') => {
  return {
    type: actionTypes.FETCH_POSTS_BY_USER,
    request: {
      url: `/posts/byUser/${username}/${filter}`,
      method: 'get'
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};
export const fetchPostsByMonth = (month, groupName = '', filter = '') => {
  let url = `/posts/byMonth/${month}/${groupName}`;
  if (filter === false) url = `/posts/byMonth/${month}/${groupName}/false`;
  return {
    type: actionTypes.FETCH_POSTS_BY_MONTH,
    request: {
      url,
      method: 'get'
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};
