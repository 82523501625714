import { put, debounce } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as actionTypes from '../actions/actionTypes';

function* fetchSearchResults(action) {
  yield put(actions.fetchSearchResultsRequest(action));
}

export function* debounceSearch() {
  yield debounce(500, actionTypes.FETCH_SEARCH_RESULTS, fetchSearchResults);
}
