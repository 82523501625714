import * as actionTypes from '../actions/actionTypes';

export const getFileUploadUrlPromise = (meta) => ({
  type: actionTypes.FILE_UPLOAD_GET_UPLOAD_URL,
  request: {
    url: `/files/FileStorage/GeneratePreSignedURL`,
    method: 'post',
    data: meta
  },
  meta: { asPromise: true, name: meta.name, size: meta.size, type: meta.type }
});

export const getImageWithAuth = (url, noPrefix = false) => ({
  type: actionTypes.GET_IMAGE_WITH_AUTH,
  request: {
    url: noPrefix ? url : `/files${url}`,
    method: 'get',
    responseType: 'arraybuffer'
  },
  meta: { asPromise: true }
});

// export const downloadFile = (fileId, download = true) => ({
//   type: actionTypes.DOCUMENTS_DOWNLOAD,
//   request: {
//     url: `/files/FileStorage/GetPreSignedFileURL/${fileId}`,
//     method: 'get'
//   },
//   meta: {
//     fileId,
//     download
//   }
// });
