import * as actionTypes from '../actions/actionTypes';

export const fetchJournal = ({ pageId, queryString }) => ({
  type: actionTypes.JOURNAL_FETCH,
  request: {
    url: `/Journal/Event/ShowLines/${queryString}`,
    method: 'post',
    data: {}
  },
  meta: {
    pageId,
    queryString,
    type: 'journal'
  }
});

export const fetchSingleJournalEntry = ({ lineCode }) => ({
  type: actionTypes.JOURNAL_FETCH_SINGLE_ENTRY,
  request: {
    url: `/Journal/Event/ShowJournalLine/${lineCode}`,
    method: 'get'
  },
  meta: {
    lineCode,
    type: 'journalEntry',
    asPromise: true
  }
});

export const fetchJournalDocuments = ({ pageId, queryString }) => ({
  type: actionTypes.JOURNAL_FETCH_DOCUMENTS,
  request: {
    url: `/Journal/Event/ShowAllDocuments/${queryString}`,
    method: 'post',
    data: {}
  },
  meta: {
    pageId,
    queryString,
    type: 'journal',
    asPromise: true
  }
});

export const updateJournalFilesCategories = ({
  targetCategoryId,
  fileIds
}) => ({
  type: actionTypes.JOURNAL_UPDATE_DOCUMENTS_CATEGORY,
  request: {
    url: `/Journal/Event/UpdateFilesCategory`,
    method: 'post',
    data: {
      targetCategoryId,
      fileIds
    }
  },
  meta: {
    type: 'journal',
    asPromise: true
  }
});

export const fetchJournalCategories = () => ({
  type: actionTypes.JOURNAL_FETCH_CATEGORIES,
  request: {
    url: `/Journal/Config/GetAllCategories`,
    method: 'get'
  },
  meta: {
    type: 'journal',
    asPromise: true
  }
});

export const newJournalEntry = ({ pageId, objectId, form }) => ({
  type: actionTypes.JOURNAL_NEW_ENTRY,
  request: {
    // url: `/Journal/Event/ShowLines/${queryString}`,
    url: `/Journal/Event/Line/${objectId}`,
    method: 'post',
    data: { ...form }
  },
  meta: {
    objectId,
    pageId,
    form,
    type: 'journal',
    asPromise: true
  }
});

export const deleteJournalEntry = ({ pageId, objectId, lineId }) => ({
  type: actionTypes.JOURNAL_DELETE_ENTRY,
  request: {
    url: `/Journal/Event/Line/`,
    method: 'delete',
    data: [lineId]
  },
  meta: {
    objectId,
    pageId,
    lineId,
    type: 'journal',
    asPromise: true
  }
});

export const addFilesToJournalEntry = ({
  pageId,
  objectId,
  lineId,
  files
}) => ({
  type: actionTypes.JOURNAL_ADD_FILES,
  request: {
    url: `/Journal/Event/AddFilesToLine/`,
    method: 'post',
    data: { LineCode: lineId, Files: files }
  },
  meta: {
    objectId,
    pageId,
    lineId,
    files,
    type: 'journal',
    asPromise: true
  }
});

export const editJournalEntry = ({ pageId, objectId, form, lineId }) => ({
  type: actionTypes.JOURNAL_EDIT_ENTRY,
  request: {
    // url: `/Journal/Event/ShowLines/${queryString}`,
    url: `/Journal/Event/Line/${lineId}`,
    method: 'put',
    data: { ...form }
  },
  meta: {
    objectId,
    pageId,
    form,
    lineId,
    type: 'journal',
    asPromise: true
  }
});

export const exportJournalEntries = ({
  pageId,
  objectId,
  format = 'XLSX'
}) => ({
  type: actionTypes.JOURNAL_EXPORT_FETCH,
  request: {
    // url: `/Journal/Event/ShowLines/${queryString}`,
    url: `/Journal/Event/export/${format}/${objectId}`,
    method: 'post',
    data: {},
    responseType: 'blob'
  },
  meta: {
    objectId,
    pageId,
    format,
    type: 'journal'
  }
});
