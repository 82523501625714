// Authentication
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_RESTORE = 'AUTH_RESTORE';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SAGA_RESTART = 'AUTH_SAGA_RESTART';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_STORAGE_CHANGE = 'AUTH_STORAGE_CHANGE';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SET_NEW = 'RECOVER_PASSWORD_SET_NEW';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESEND_ACTIVATION = 'RESEND_ACTIVATION';
export const SET_FILTER = 'SET_FILTER';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const DELETE_AVATAR = 'DELETE_AVATAR';
export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const UPDATE_AVATAR_URL = 'UPDATE_AVATAR_URL';

// UI Settings
export const UPDATE_UI_SETTINGS = 'UPDATE_UI_SETTINGS';
export const TOGGLE_UI_LAYOUT = 'TOGGLE_UI_LAYOUT';
export const RELOAD_USER_SETTINGS = 'RELOAD_USER_SETTINGS';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const RESET_USER_SETTINGS = 'RESET_USER_SETTINGS';

// Authentication - Version check
export const CHECK_VERSION = 'CHECK_VERSION';
export const CHECK_VERSION_REQUEST = 'CHECK_VERSION_REQUEST';
export const VERSION_SET_OUTDATED = 'VERSION_SET_OUTDATED';

// Authentication - Programs
export const GET_ALLOWED_PROGRAMS = 'GET_ALLOWED_PROGRAMS';
export const SET_PROGRAM = 'SET_PROGRAM';
export const SET_ROLE = 'SET_ROLE';
export const SET_FLEET = 'SET_FLEET';

// Registration
export const REGISTER = 'REGISTER';

// Dynamic
export const FETCH_MENU = 'FETCH_MENU';
export const FETCH_DYNAMIC_PAGE = 'FETCH_DYNAMIC_PAGE';
export const FETCH_DYNAMIC_PAGE_REQUEST = 'FETCH_DYNAMIC_PAGE_REQUEST';
export const CLEAR_DYNAMIC_SESSION = 'CLEAR_DYNAMIC_SESSION';
export const CLEAR_DYNAMIC_REDIRECT = 'CLEAR_DYNAMIC_REDIRECT';

// Form engine
export const FORMENGINE_INITIALIZE = 'FORMENGINE_INITIALIZE';
export const FORMENGINE_CLIENT_CHANGE = 'FORMENGINE_CLIENT_CHANGE';
export const FORMENGINE_SERVER_CHANGE = 'FORMENGINE_SERVER_CHANGE';
export const FORMENGINE_SERVER_ARRAY_CHANGE = 'FORMENGINE_SERVER_ARRAY_CHANGE';
export const FORMENGINE_SERVER_FIELD_CHANGE = 'FORMENGINE_SERVER_FIELD_CHANGE';
export const FORMENGINE_SERVER_CHANGE_DATA_UPDATE =
  'FORMENGINE_SERVER_CHANGE_DATA_UPDATE';
export const FORMENGINE_CLIENT_EVENT = 'FORMENGINE_CLIENT_EVENT';
export const FORMENGINE_SERVER_EVENT = 'FORMENGINE_SERVER_EVENT';
export const FORMENGINE_SUBMIT_EVENT_SEND = 'FORMENGINE_SUBMIT_EVENT_SEND';
export const FORMENGINE_SUBMIT_EVENT_TRIGGER =
  'FORMENGINE_SUBMIT_EVENT_TRIGGER';
export const FORMENGINE_EVENT_TRIGGER = 'FORMENGINE_EVENT_TRIGGER';
export const FORMENGINE_FILE_UPLOAD_ACTION = 'FORMENGINE_FILE_UPLOAD_ACTION';
export const FORMENGINE_WORKFLOW_TRIGGER = 'FORMENGINE_WORKFLOW_TRIGGER';
export const FORMENGINE_WORKFLOW_TRIGGER_SEND =
  'FORMENGINE_WORKFLOW_TRIGGER_SEND';
export const FORMENGINE_WORKFLOW_RELOAD = 'FORMENGINE_WORKFLOW_RELOAD';
export const FORMENGINE_KEEP_SESSION_ALIVE = 'FORMENGINE_KEEP_SESSION_ALIVE';
export const OPEN_EXTERNAL_PAGE = 'OPEN_EXTERNAL_PAGE';
export const FORMENGINE_WORKFLOW_UPDATE_MAIN_FORM =
  'FORMENGINE_WORKFLOW_UPDATE_MAIN_FORM';

//File upload
export const FILE_UPLOAD_GET_UPLOAD_URL = 'FILE_UPLOAD_GET_UPLOAD_URL';
export const GET_IMAGE_WITH_AUTH = 'GET_IMAGE_WITH_AUTH';

// Documents
export const DOCUMENTS_FETCH = 'DOCUMENTS_FETCH';
export const DOCUMENTS_DOWNLOAD = 'DOCUMENTS_DOWNLOAD';
export const DOCUMENTS_BY_OBJECT_ID_FETCH = 'DOCUMENTS_BY_OBJECT_ID_FETCH';
export const DOCUMENTS_PRINT_PDF = 'DOCUMENTS_PRINT_PDF';
export const DOCUMENTS_SAFE_HTML = 'DOCUMENTS_SAFE_HTML';
export const DOCUMENTS_DOWNLOAD_AS_A_ZIP = 'DOCUMENTS_DOWNLOAD_AS_A_ZIP';

// Table
export const TABLE_RECORDS_FETCH = 'TABLE_RECORDS_FETCH';
export const TABLE_RECORDS_FETCH_REQUEST = 'TABLE_RECORDS_FETCH_REQUEST';
export const MAKE_CUSTOM_REQUEST = 'MAKE_CUSTOM_REQUEST';
export const TABLE_EXPORT_FETCH = 'TABLE_EXPORT_FETCH';
export const TABLE_OPEN_MODAL = 'TABLE_OPEN_MODAL';

// Letters
// export const LETTER_TEMPLATES_FETCH = 'LETTER_TEMPLATES_FETCH';
export const LETTER_GENERATE_TEMPLATE = 'LETTER_GENERATE_TEMPLATE';
export const LETTER_GENERATE_EMAIL_REPLY_TEMPLATE =
  'LETTER_GENERATE_EMAIL_REPLY_TEMPLATE';
export const LETTER_GENERATE_TEMPLATE_WITH_SESSION =
  'LETTER_GENERATE_TEMPLATE_WITH_SESSION';
export const LETTER_SEND_BY_EMAIL = 'LETTER_SEND_BY_EMAIL';
export const LETTER_GENERATE_PDF = 'LETTER_GENERATE_PDF';
export const LETTER_GENERATE_COMBINED_PRINT_PDF =
  'LETTER_GENERATE_COMBINED_PRINT_PDF';

// Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const REPLACE_MODAL = 'REPLACE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

// Search
export const FETCH_SEARCH_RESULTS_REQUEST = 'FETCH_SEARCH_RESULTS_REQUEST';
export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';

// Journal
export const JOURNAL_FETCH = 'JOURNAL_FETCH';
export const JOURNAL_FETCH_DOCUMENTS = 'JOURNAL_FETCH_DOCUMENTS';
export const JOURNAL_FETCH_CATEGORIES = 'JOURNAL_FETCH_CATEGORIES';
export const JOURNAL_NEW_ENTRY = 'JOURNAL_NEW_ENTRY';
export const JOURNAL_DELETE_ENTRY = 'JOURNAL_DELETE_ENTRY';
export const JOURNAL_ADD_FILES = 'JOURNAL_ADD_FILES';
export const JOURNAL_EDIT_ENTRY = 'JOURNAL_EDIT_ENTRY';
export const JOURNAL_EXPORT_FETCH = 'JOURNAL_EXPORT_FETCH';
export const JOURNAL_FETCH_SINGLE_ENTRY = 'JOURNAL_FETCH_SINGLE_ENTRY';
export const LETTER_JOURNAL_PRINT_ENTRY = 'LETTER_JOURNAL_PRINT_ENTRY';
export const JOURNAL_UPDATE_DOCUMENTS_CATEGORY =
  'JOURNAL_UPDATE_DOCUMENTS_CATEGORY';

//Reports
export const REPORTS_FETCH_FOLDERS = 'REPORTS_FETCH_FOLDERS';
export const REPORTS_FETCH_REPORTS = 'REPORTS_FETCH_REPORTS';
export const REPORTS_CREATE_FOLDER = 'REPORTS_CREATE_FOLDER';
export const REPORTS_DELETE_REPORT = 'REPORTS_DELETE_REPORT';
export const REPORTS_FETCH_METADATA = 'REPORTS_FETCH_METADATA';
export const REPORTS_PREVIEW = 'REPORTS_PREVIEW';
export const REPORTS_RUN_AND_DOWNLOAD = 'REPORTS_RUN_AND_DOWNLOAD';
export const REPORTS_DOWNLOAD = 'REPORTS_DOWNLOAD';
export const REPORTS_GENERATE = 'REPORTS_GENERATE';
export const REPORTS_ADD_FILES_TO_FOLDER = 'REPORTS_ADD_FILES_TO_FOLDER';
export const REPORTS_MOVE_REPORT = 'REPORTS_MOVE_REPORT';
export const REPORTS_RENAME_REPORT = 'REPORTS_RENAME_REPORT';
export const REPORTS_EDITOR_FETCH_CONFIG = 'REPORTS_EDITOR_FETCH_CONFIG';
export const REPORTS_EDITOR_PREVIEW = 'REPORTS_EDITOR_PREVIEW';
export const REPORTS_EDITOR_FETCH_FILTERS = 'REPORTS_EDITOR_FETCH_FILTERS';
export const REPORTS_EDITOR_SAVE_CHANGES = 'REPORTS_EDITOR_SAVE_CHANGES';
export const REPORTS_EDITOR_FETCH_FILTER_FORM_CONFIG =
  'REPORTS_EDITOR_FETCH_FILTER_FORM_CONFIG';

//Processing
export const PROCESSING_IMPORT_GENERIC_DOCUMENT =
  'PROCESSING_IMPORT_GENERIC_DOCUMENT';

export const PROCESSING_FETCH_SAMPLE_IMPORT_FILE =
  'PROCESSING_FETCH_SAMPLE_IMPORT_FILE';

//Proposal
export const PROPOSAL_DATA_BY_BARCODE = 'PROPOSAL_DATA_BY_BARCODE';
export const PROPOSAL_CONFIRM = 'PROPOSAL_CONFIRM';
export const PROPOSAL_POLICY_FILE_SOURCE = 'PROPOSAL_POLICY_FILE_SOURCE';

//Tasks
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const COMPLETE_TASK_REQUEST = 'COMPLETE_TASK_REQUEST';
export const POSTPONE_TASK_REQUEST = 'POSTPONE_TASK_REQUEST';
export const TASK_DRAWER_VISIBILITY = 'TASK_DRAWER_VISIBILITY';
export const TASK_DRAWER_VISIBILITY_TOGGLE = 'TASK_DRAWER_VISIBILITY_TOGGLE';

//History
export const HISTORY_FETCH_OBJECT_CHANGES = 'HISTORY_FETCH_OBJECT_CHANGES';

//Fleet
export const FLEET_FETCH_ALL = 'FLEET_FETCH_ALL';
export const FLEET_FETCH_SINGLE = 'FLEET_FETCH_SINGLE';
export const FLEET_CREATE = 'FLEET_CREATE';
export const FLEET_UPDATE = 'FLEET_UPDATE';
export const FLEET_MOVE = 'FLEET_MOVE';
export const FLEET_PERMISSION_DICTIONARIES = 'FLEET_PERMISSION_DICTIONARIES';
export const FLEET_PERMISSION_USERS = 'FLEET_PERMISSION_USERS';
export const FLEET_DELETE = 'FLEET_DELETE';
export const FLEET_PERMISSION_USERS_CREATE = 'FLEET_PERMISSION_USERS_CREATE';
export const FLEET_PERMISSION_DELETE = 'FLEET_PERMISSION_DELETE';
export const FLEET_NODE_FILTER = 'FLEET_NODE_FILTER';
export const FLEET_USERS_CREATE = 'FLEET_USERS_CREATE';

//User
export const CREATE_USER = 'CREATE_USER';

//Activity
export const SEND_ACTIVITY_REQUEST = 'SEND_ACTIVITY_REQUEST';

//Stories
export const FETCH_STORIES = 'FETCH_STORIES';
export const FETCH_STORIES_SUMMARY = 'FETCH_STORIES_SUMMARY';
export const FETCH_STORIES_BY_MONTH = 'FETCH_STORIES_BY_MONTH';
export const FETCH_STORIES_BY_USER = 'FETCH_STORIES_BY_USER';
export const STORIES_DOWNLOAD = 'STORIES_DOWNLOAD';
export const PROFILES_SUMMARY = 'PROFILES_SUMMARY';
export const SINGLE_PROFILE = 'SINGLE_PROFILE';
export const FETCH_POSTS_SUMMARY = 'FETCH_POSTS_SUMMARY';
export const FETCH_POSTS_BY_USER = 'FETCH_POSTS_BY_USER';
export const FETCH_POSTS_BY_MONTH = 'FETCH_POSTS_BY_MONTH';
export const POST_BACKLOG_DOWNLOAD = 'POST_BACKLOG_DOWNLOAD';
