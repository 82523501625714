import * as actionTypes from '../actions/actionTypes';
// import { success } from 'redux-saga-requests';

// used to reset previous settings
const resetTimestamp = 0;

const defaultUserSettings = { tasksView: 'drawer', layout: null };

const getUserSettingsFromStorage = () => {
  try {
    let userSettingsFromStorage =
      JSON.parse(localStorage.getItem('userSettings')) || {};

    if (
      !userSettingsFromStorage.timestamp ||
      userSettingsFromStorage.timestamp < resetTimestamp
    ) {
      localStorage.removeItem('userSettings');
      return defaultUserSettings;
    }

    return userSettingsFromStorage;
  } catch (error) {
    console.log('Error parsing user settings, deleting them.');
    localStorage.removeItem('userSettings');
    return defaultUserSettings;
  }
};

let userSettings = getUserSettingsFromStorage();

const initialState = {
  antSettings: {
    fixSiderbar: true,
    fixedHeader: true,
    navTheme: 'light',
    layout: 'topmenu',
    contentWidth: 'Fixed'
  },
  userSettings: {
    ...defaultUserSettings,
    ...userSettings
  }
};

const updateAntSettings = (state, action) => {
  if (action.overwrite) {
    return { ...state, antSettings: action.settings };
  } else {
    return {
      ...state,
      antSettings: { ...state.antSettings, ...action.settings }
    };
  }
};

const reloadUserSettings = (state, action) => {
  const userSettings = getUserSettingsFromStorage();
  return { ...state, userSettings };
};

const resetUserSettings = (state, action) => {
  localStorage.removeItem('userSettings');
  return {
    ...state,
    userSettings: {
      tasksView: 'drawer',
      layout: null
    }
  };
};

const updateUserSettings = (state, action) => {
  const { settings } = action;
  const envDate = process.env.REACT_APP_GIT_DATE
    ? new Date(process.env.REACT_APP_GIT_DATE)
    : new Date();
  const timestamp = envDate.valueOf();

  const newUserSettings = { ...state.userSettings, ...settings, timestamp };

  localStorage.setItem('userSettings', JSON.stringify(newUserSettings));
  return { ...state, userSettings: newUserSettings };
};

const toggleLayout = (state, action) => {
  if (state.antSettings.layout === 'topmenu') {
    return {
      ...state,
      antSettings: {
        ...state.antSettings,
        autoHideHeader: true,
        contentWidth: 'Fluid',
        layout: 'sidemenu'
      }
    };
  } else {
    return {
      ...state,
      antSettings: {
        ...state.antSettings,
        autoHideHeader: false,
        contentWidth: 'Fixed',
        layout: 'topmenu',
        collapsed: false
      }
    };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UI_SETTINGS:
      return updateAntSettings(state, action);
    case actionTypes.TOGGLE_UI_LAYOUT:
      return toggleLayout(state, action);
    case actionTypes.RELOAD_USER_SETTINGS:
      return reloadUserSettings(state, action);
    case actionTypes.UPDATE_USER_SETTINGS:
      return updateUserSettings(state, action);
    case actionTypes.RESET_USER_SETTINGS:
      return resetUserSettings(state, action);
    default:
      return state;
  }
};

export default reducer;
