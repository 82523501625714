import { all, select, put, call } from 'redux-saga/effects';
import * as actions from '../actions';
import { autofill } from 'redux-form';
import cloneDeep from 'lodash-es/cloneDeep';
import ObservableSlim from 'observable-slim';
import { push, replace } from 'connected-react-router';
import { getFormSyncErrors, getFormValues, touch } from 'redux-form';
import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';
import { notification as antNotification, Modal } from 'antd';

// import * as actionTypes from '../actions/actionTypes';

const getRegisteredFields = (formId) => (state) =>
  Object.keys(state.form[formId].registeredFields);

export function* clientSideActionSaga(action) {
  const { formId, sessionId, fieldName, arrayAction } = action.meta;
  if (sessionId) {
    const formObject = yield select((state) => state.form[formId]);
    const storeData = (formObject || {}).values;
    const engine = yield select(
      (state) => state.formEngine.sessionIds[sessionId]
    );
    let triggers = engine && engine.triggers && engine.triggers[fieldName];
    if (arrayAction) {
      // console.log('array field....', arrayAction);
      triggers = engine.triggers[arrayAction.subKey];
    }

    if (
      triggers &&
      Array.isArray(triggers.actions) &&
      !triggers.hasBackendOnlyAction
    ) {
      try {
        const formData = cloneDeep(storeData);

        let proxyChanges = {};
        // if (arrayAction) {
        //   console.log('array field....', arrayAction);
        //   const { subKey, rootKey, index, action } = arrayAction;
        // }

        //eslint-disable-next-line
        let data = ObservableSlim.create(formData, false, function (changes) {
          changes.forEach(
            (change) => (proxyChanges[change.currentPath] = change.newValue)
          );
        });

        //eslint-disable-next-line
        let formState = ObservableSlim.create(
          formData.formMeta || {},
          false,
          function (changes) {
            changes.forEach(
              (change) =>
                (proxyChanges[`formMeta.${change.currentPath}`] =
                  change.newValue)
            );
          }
        );

        //eslint-disable-next-line
        const isServer = false;
        //eslint-disable-next-line
        const Log = (key) => {
          console.log('SCRIPT LOG', key);
        };
        //eslint-disable-next-line
        const enable = (key) => {
          if (formState[key]) {
            formState[key].disabled = false;
          } else {
            formState[key] = { disabled: false };
          }
        };
        //eslint-disable-next-line
        const disable = (key) => {
          if (formState[key]) {
            formState[key].disabled = true;
          } else {
            formState[key] = { disabled: true };
          }
        };
        //eslint-disable-next-line
        const show = (key) => {
          if (formState[key]) {
            formState[key].hidden = false;
          } else {
            formState[key] = { hidden: false };
          }
        };
        //eslint-disable-next-line
        const hide = (key) => {
          if (formState[key]) {
            formState[key].hidden = true;
          } else {
            formState[key] = { hidden: true };
          }
        };

        //eslint-disable-next-line
        for (let action of triggers.actions) {
          const script = engine.actions[action.actionId].script;
          //eslint-disable-next-line
          yield eval(script);
          yield put({
            type: `FORMENGINE_ACTION: ${engine.actions[action.actionId].name}`,
            payload: { changes: proxyChanges }
          });
        }

        ObservableSlim.remove(data);
        ObservableSlim.remove(formState);

        // console.log('Changes: ', proxyChanges);
        yield all(
          Object.keys(proxyChanges).map((field) => {
            const value = proxyChanges[field];
            return put(autofill(formId, field, value));
          })
        );
      } catch (error) {
        console.log('error', error);
      }
    }
  }
}

export function* serverArrayChangeSaga(action) {
  // console.log(action.meta);
  const { formId, sessionId, rootKey, arrayAction } = action.meta;
  const engine = yield select(
    (state) => state.formEngine.sessionIds[sessionId]
  );
  let triggers = engine.triggers[rootKey];

  if (arrayAction && arrayAction.subKey) {
    // console.log('array field....', arrayAction);
    triggers = engine.triggers[arrayAction.subKey];
  }

  if (triggers) {
    // const newValue = yield select(
    //   (state) => state.form[formId].values[rootKey]
    // );
    const { formMeta, ...data } = yield select(getFormValues(formId));
    yield put(
      actions.serverChangeAction({
        sessionId: sessionId,
        formId: formId,
        trigger: rootKey,
        arrayAction,
        newValue: data[rootKey],
        data: data,
        updateWithServerValues: triggers.hasBackendOnlyAction,
        hideLoader: !triggers.hasBackendOnlyAction,
        formMeta
      })
    );
  }
}

export function* serverFieldChangeSaga(action) {
  const { formId, sessionId, trigger, override } = action.meta;
  const engine = yield select(
    (state) => state.formEngine.sessionIds[sessionId]
  );
  const triggers = engine && engine.triggers && engine.triggers[trigger];

  if (triggers || override) {
    const formValues = yield select(getFormValues(formId));

    if (formValues) {
      const { formMeta, ...data } = formValues;
      yield put(
        actions.serverChangeAction({
          ...action.meta,
          data: data,
          updateWithServerValues: override
            ? true
            : triggers.hasBackendOnlyAction,
          hideLoader: override ? false : !triggers.hasBackendOnlyAction,
          formMeta
        })
      );
    }
  }
}

export function* updateFormWithServerValuesSaga(action) {
  if (action.meta.updateWithServerValues) {
    // this block merges in the tabstate property
    const currentFormMeta = action.meta.formMeta || {};
    const currentTabKeys = Object.keys(currentFormMeta).filter(
      (key) => currentFormMeta[key] && currentFormMeta[key].currentTab
    );
    const mergedInTabKeys = currentTabKeys.reduce((acc, key) => {
      const currentTabKey = currentFormMeta[key];
      const incomingTabKey = action.data.formState[key] || {};
      acc[key] = { ...currentTabKey, ...incomingTabKey };
      return acc;
    }, {});

    const data = {
      ...action.data.state,
      formMeta: { ...action.data.formState, ...mergedInTabKeys }
    };

    const errors = action.data.validationErrors
      ? { ...action.data.validationErrors }
      : {};
    const formId = action.meta.formId;
    yield put({
      type: '@@redux-form/REINITIALIZE',
      data: data,
      formId,
      errors
    });
  }

  if (action.data.eventAction && action.data.eventAction === 'RefreshTable') {
    const location = yield select((state) => state.router.location);
    const { search } = location;
    const queryStringParams = new URLSearchParams(search);

    const pageParams = yield select(
      (state) => state.dynamic.ids[action.meta.tableId]
    ) || {};
    const { sourceParams = {} } = pageParams;

    yield put(
      actions.fetchTableRecords({
        ...sourceParams,
        pageIndex: 0,
        pageId: action.meta.tableId,
        sessionId: action.meta.sessionId,
        queryStringParams
      })
    );
  }
}

export function* initializeFormSaga(action) {
  const data = { ...action.meta.formData };
  const formId = action.meta.formId;
  const errors = action.meta.validationErrors
    ? { ...action.meta.validationErrors }
    : null;
  yield put({ type: '@@redux-form/REINITIALIZE', data: data, formId, errors });
}

export function* serverEventCompleteSaga(action) {
  console.log('server event complete', action);

  const {
    eventAction,
    eventActionParams,
    notification,
    state,
    refreshHeading = false,
    validationErrors = {}
  } = action.data;

  if (notification) {
    const {
      type = 'info',
      message = 'Nie ustawiono wiadomości',
      description,
      placement = 'topRight',
      duration = 4.5,
      top = 24
    } = notification;

    antNotification[type.toLowerCase()]({
      className: `notification-${type}`,
      message,
      description,
      duration,
      placement,
      top
    });
  }
  switch (eventAction) {
    case 'RedirectToPage': {
      const redirectUrl = {
        pathname: `/${eventActionParams.pageId}`
      };
      if (eventActionParams.tabPageId) {
        redirectUrl.hash = eventActionParams.tabPageId;
      }
      if (eventActionParams.queryStringParams)
        redirectUrl.search = `?${new URLSearchParams(
          eventActionParams.queryStringParams
        ).toString()}`;
      yield put(actions.closeAllModals());
      yield put(push(redirectUrl));
      break;
    }
    case 'Recalculation': {
      yield put({
        ...action,
        type: success(actionTypes.FORMENGINE_SERVER_CHANGE)
      });

      if (action.meta.customEvent === 'tableRefresh') {
        const pageParams = yield select(
          (state) => state.dynamic.ids[action.meta.tableId]
        ) || {};
        const { sourceParams = {} } = pageParams;
        yield put(
          actions.fetchTableRecords({
            ...sourceParams,
            pageIndex: 0,
            pageId: action.meta.tableId,
            sessionId: action.meta.sessionId
          })
        );
      }

      break;
    }
    case 'RefreshTable':
      const location = yield select((state) => state.router.location);
      const { search } = location;
      const queryStringParams = new URLSearchParams(search);

      const pageParams = yield select(
        (state) => state.dynamic.ids[action.meta.tableId]
      ) || {};
      const { sourceParams = {} } = pageParams;

      yield put({
        ...action,
        data: { ...action.data, eventAction: 'Recalculation' },
        type: success(actionTypes.FORMENGINE_SERVER_CHANGE)
      });

      yield put(
        actions.fetchTableRecords({
          ...sourceParams,
          pageIndex: 0,
          pageId: action.meta.tableId,
          sessionId: action.meta.sessionId,
          queryStringParams
        })
      );
      break;
    case 'RefreshPage': {
      const location = yield select((state) => state.router.location);
      const { formId, locationSearch } = action.meta;
      const { search } = location;

      if (Object.keys(validationErrors).length > 0) {
        console.log('error');
        yield put({
          ...action,
          type: success(actionTypes.FORMENGINE_SERVER_CHANGE)
        });
      } else {
        const formIdGuid = formId.length > 36 ? formId.slice(0, 36) : formId;
        yield put(
          actions.fetchDynamicPage(formIdGuid, locationSearch || search)
        );
      }
      break;
    }
    case 'DownloadTemplateFile':
      yield put(actions.getSampleImportFile(eventActionParams.fileId));
      break;
    case 'DownloadFile':
      yield put(actions.downloadFile(eventActionParams.fileId));
      break;
    case 'GenerateFile':
      yield put(actions.generateFile({ data: state }));
      break;
    case 'OpenExternalPage':
      yield put(actions.openExternalUrl(eventActionParams.targetUrl));
      break;
    case 'PrintPdf':
      yield put(actions.printPdf(eventActionParams.fileId));
      break;
    case 'OpenModal':
      if (eventActionParams.queryStringParams) {
        eventActionParams.queryString = `?${new URLSearchParams(
          eventActionParams.queryStringParams
        ).toString()}`;
      }
      const { sessionId, formId } = action.meta;
      eventActionParams.parentForm = { sessionId, formId };
      if (eventActionParams.type && eventActionParams.type === 'letter') {
        const { letterId, objectId } = eventActionParams.queryStringParams;
        eventActionParams.id = `${letterId}-${objectId}`;
        eventActionParams.letterId = letterId;
        eventActionParams.objectId = objectId;
        eventActionParams.disableBackgroundClick = true;
      }
      if (eventActionParams.closeModalId) {
        yield put(actions.replaceModal(eventActionParams));
      } else {
        yield put(actions.openModal(eventActionParams));
      }

      break;
    case 'CloseModal':
      if (eventActionParams.refresh) {
        const location = yield select((state) => state.router.location);
        const { search, pathname } = location;
        const id = pathname.split('/').pop();
        yield put(actions.fetchDynamicPage(id, search));
      } else if (eventActionParams.refreshNested) {
        const modalObj = yield select(
          (state) => state.modal.ids[eventActionParams.id]
        );

        const { opener = {} } = modalObj || {};
        if (opener.type === 'table') {
          yield put(
            actions.fetchTableRecords({
              pageId: opener.tableId,
              queryStringParams: opener.tableSearch
            })
          );
        }

        // // check if triggered by form and send onchange

        if (opener.parentSessionId) {
          yield put(
            actions.serverFieldChangeAction({
              sessionId: opener.parentSessionId,
              formId: opener.parentFormId,
              override: true,
              trigger: opener.formKey,
              newValue: null
            })
          );
        }
      } else if (eventActionParams.redirect) {
        let path = `/${eventActionParams.pageId}`;
        let hash = '';
        if (eventActionParams.tabPageId) {
          hash = eventActionParams.tabPageId;
        }
        const redirectUrl = {
          pathname: path,
          hash
        };
        if (eventActionParams.queryStringParams)
          redirectUrl.search = `?${new URLSearchParams(
            eventActionParams.queryStringParams
          ).toString()}`;
        yield put(actions.closeAllModals());
        yield put(push(redirectUrl));
      } else if (eventActionParams.customEventCallback) {
        const modalObj = yield select(
          (state) => state.modal.ids[eventActionParams.id]
        );
        const { sessionId, formId } = modalObj.parentForm;
        yield put(
          actions.formEventTriggerAction({
            sessionId: sessionId,
            formId: formId,
            customEvent: eventActionParams.customEventCallback,
            buttonName: eventActionParams.customEventCallback
          })
        );
      } else if (eventActionParams.setField) {
        const modalObj = yield select(
          (state) => state.modal.ids[eventActionParams.id]
        );
        const { sessionId, formId } = modalObj.parentForm;
        yield put(
          actions.serverChangeAction({
            sessionId: sessionId,
            formId: formId,
            trigger: eventActionParams.setField,
            newValue: eventActionParams.setFieldValue,
            updateWithServerValues: true
          })
        );
      }

      yield put(actions.closeModal(eventActionParams));

      break;
    default:
      break;
  }

  if (action.meta.customEvent === 'createFleet') {
    yield put(
      actions.setFleet(null, eventActionParams.queryStringParams.objectId)
    );
  }

  if (refreshHeading) {
    const location = yield select((state) => state.router.location);
    const pathGuid = location.pathname.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
    );
    const containerPage = yield select(
      (state) => state.dynamic.ids[pathGuid[0]]
    ) || {};
    const { config = {} } = containerPage;
    const { headingConfig = {} } = config;
    const headingSessionId = headingConfig.sessionId;

    if (headingSessionId) {
      yield put(
        actions.fetchDynamicPageRequest(
          pathGuid[0],
          location.search,
          false,
          true
        )
      );
    }
  }

  if (eventActionParams && eventActionParams.getAllowedPrograms)
    yield put(actions.getAllowedPrograms());
  if (eventActionParams && eventActionParams.updateToken)
    yield put(actions.updateToken());
}

export function* formEventSaga(action) {
  // console.log('saga triggered', action);

  const { formId, validateAll } = action.meta;
  const formObject = yield select(getFormValues(formId));

  if (!formObject) {
    antNotification.error({
      className: `notification-error`,
      message: 'Wystąpił błąd, brak podanego formularza',
      placement: 'topRight',
      duration: 4.5,
      top: 24
    });
    return;
  }
  const { formMeta, ...data } = formObject;
  const updateWithServerValues = true;
  // console.log(formId, data, action);

  if (!validateAll) {
    return yield put(
      actions.serverEventAction({
        ...action.meta,
        data,
        updateWithServerValues,
        formMeta
      })
    );
  }

  const errors = yield select(getFormSyncErrors(formId)) || {};

  // Touch all fields
  const fields = yield select(getRegisteredFields(formId));
  yield put(touch(formId, ...fields));

  const filteredErrors = Object.keys(errors).filter((error) => {
    if (Array.isArray(errors[error])) {
      const arrayFiltered = errors[error].filter(
        (element) => element && Object.keys(element).length > 0
      );
      if (arrayFiltered.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  });

  if (errors && filteredErrors.length > 0) {
    console.log('there are errors not letting event through!');
    const fields = yield select(getRegisteredFields(formId));
    yield put(touch(formId, ...fields));
    alert('Proszę poprawić formularz');
  } else {
    yield put(
      actions.serverEventAction({
        ...action.meta,
        data,
        updateWithServerValues,
        formMeta
      })
    );
  }
}

export function* formSubmitEventSaga(action) {
  // console.log('saga triggered', action);

  const { formId } = action.meta;
  const { formMeta, ...data } = yield select(getFormValues(formId));
  const updateWithServerValues = true;

  // if (!validateAll) {
  //   return yield put(actions.serverEventAction({ ...action.meta, data }));
  // }

  const errors = yield select(getFormSyncErrors(formId));

  // Touch all fields
  const fields = yield select(getRegisteredFields(formId));
  yield put(touch(formId, ...fields));

  const filteredErrors = Object.keys(errors).filter((error) => {
    if (Array.isArray(errors[error])) {
      const arrayFiltered = errors[error].filter(
        (element) => Object.keys(element).length > 0
      );
      if (arrayFiltered.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  });

  if (errors && filteredErrors.length > 0) {
    console.log('there are errors not letting event through!');
    const fields = yield select(getRegisteredFields(formId));
    yield put(touch(formId, ...fields));
    alert('Proszę poprawić formularz');
  } else {
    yield put(
      actions.serverSubmitActionSend({
        ...action.meta,
        data,
        updateWithServerValues,
        formMeta
      })
    );
  }
}

export function* workflowTriggerSaga(action) {
  // console.log('saga triggered', action);

  const { formId } = action.meta;
  const { formMeta, ...data } = yield select(getFormValues(formId));
  // console.log(formId, data, action);

  return yield put(
    actions.workflowTriggerActionSend({
      ...action.meta,
      data,
      formMeta
    })
  );
}

export function* formReloadErrorSaga() {
  const expirationDate = yield select((state) => state.auth.user.exp);
  const dateNow = yield Math.floor(Date.now() / 1000);
  console.log('Form error', expirationDate, dateNow);
  if (expirationDate && expirationDate <= dateNow) {
    const params = {
      title: 'Sesja formularza wygasła',
      content: 'Prosimy o ponowne otwarcie strony.',
      onOk() {
        window.location.reload();
      },
      okText: 'Załaduj na nowo'
    };
    console.log('Form error modal open');
    yield call(Modal.error, params);
  }
}

const getLocation = (state) => state.router.location;

export function* workflowTriggerCompleteSaga(action) {
  // console.log('workflow trigger response', action);
  const { meta, data } = action;
  const {
    command,
    config,
    // message,
    objectId,
    notification,
    pageId,
    validationErrors,
    redirectParams
  } = data;

  if (notification) {
    const {
      type = 'info',
      message = 'Nie ustawiono wiadomości',
      description,
      placement = 'topRight',
      duration = 4.5,
      top = 24
    } = notification;
    antNotification[type]({
      className: `notification-${type}`,
      message,
      description,
      duration,
      placement,
      top
    });
  }

  switch (command) {
    case 'ReloadPage':
      const location = yield select(getLocation);
      yield put(
        replace({ search: `?objectId=${objectId}`, hash: location.hash })
      );
      if (meta.modalConfirmed) {
        yield put(actions.workflowReloadAction(meta.baseFormId, config));
        yield put(actions.closeModal({ id: meta.transitionId }));
      } else if (meta.containerId) {
        const { formConfig, workflowTriggers, ...rest } = config;
        yield put(
          actions.workflowReloadAction(meta.formId, {
            formConfig,
            workflowTriggers
          })
        );
        yield put(actions.workflowReloadAction(meta.containerId, rest));
      } else {
        yield put(actions.workflowReloadAction(meta.formId, config));
      }
      break;
    case 'ValidationError':
      // const location = yield select(getLocation);
      // yield put(
      //   replace({ search: `?objectId=${objectId}`, hash: location.hash })
      // );
      // if (meta.modalConfirmed) {
      //   yield put(actions.workflowReloadAction(meta.baseFormId, config));
      //   yield put(actions.closeModal({ id: meta.transitionId }));
      // } else if (meta.containerId) {
      //   const { formConfig, workflowTriggers, ...rest } = config;
      //   yield put(
      //     actions.workflowReloadAction(meta.formId, {
      //       formConfig,
      //       workflowTriggers
      //     })
      //   );
      //   yield put(actions.workflowReloadAction(meta.containerId, rest));
      // } else {

      yield put({
        type: '@@redux-form/REINITIALIZE',
        formId: meta.formId,
        errors: validationErrors
      });

      // yield put(actions.workflowReloadAction(meta.formId, config));
      // }
      break;
    case 'Error':
      // yield call(alert, message);
      break;
    case 'RedirectToPage':
      const redirectUrl = {
        pathname: `/${pageId}`,
        search: `?objectId=${objectId}`
      };
      if (redirectParams) {
        redirectUrl.pathname = `/${redirectParams.pageId}`;
        if (redirectParams.queryStringParams) {
          redirectUrl.search = `?${new URLSearchParams(
            redirectParams.queryStringParams
          ).toString()}`;
        }
      }
      yield put(actions.closeAllModals());
      yield put(push(redirectUrl));
      break;
    case 'OpenModal':
      yield put(
        actions.openModal({
          id: meta.transitionId,
          type: 'workflow',
          data: { config: config, id: meta.transitionId },
          triggers: [meta.trigger],
          guid: meta.transitionId,
          baseFormId: meta.formId
        })
      );
      break;
    default:
      break;
  }
}

function openUrl(url) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function* openExternalPageSaga(action) {
  yield call(openUrl, action.url);
}
