import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, refreshToken, user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    refreshToken: refreshToken,
    user: user
  };
};

export const authRestore = (token, refreshToken, user) => {
  return {
    type: actionTypes.AUTH_RESTORE,
    token: token,
    refreshToken: refreshToken,
    user: user
  };
};

export const authRefresh = (token, refreshToken, user) => {
  return {
    type: actionTypes.AUTH_REFRESH,
    token: token,
    refreshToken: refreshToken,
    user: user
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = (reason) => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT,
    meta: { ...reason }
  };
};

export const authSagaRestart = (reason) => {
  return {
    type: actionTypes.AUTH_SAGA_RESTART,
    meta: { ...reason }
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

export const auth = (payload) => {
  return {
    type: actionTypes.AUTH_USER,
    payload: payload
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_STORAGE_CHANGE
  };
};

export const getAllowedPrograms = () => ({
  type: actionTypes.GET_ALLOWED_PROGRAMS,
  request: {
    url: `/identity/ProfilePermissions/AllowedPrograms`,
    method: 'get'
  },
  meta: {}
});

export const setProgram = (
  currentProgramId,
  newProgramId,
  redirect = false
) => ({
  type: actionTypes.SET_PROGRAM,
  request: {
    url: `/identity/ChangeProgram/${newProgramId}`,
    method: 'post',
    data: {}
  },
  meta: { currentProgramId, newProgramId, redirect }
});

export const setRole = (currentRoleId, newRoleId) => ({
  type: actionTypes.SET_ROLE,
  request: {
    url: `/identity/ChangeRole/${newRoleId}`,
    method: 'post',
    data: {}
  },
  meta: { currentRoleId, newRoleId }
});

export const setFleet = (currentFleetId, newFleetId) => ({
  type: actionTypes.SET_FLEET,
  request: {
    url: `/identity/ChangeFleet/${newFleetId}`,
    method: 'post',
    data: {}
  },
  meta: { currentFleetId, newFleetId }
});

export const changePassword = ({ oldPassword, newPassword }) => ({
  type: actionTypes.CHANGE_PASSWORD,
  request: {
    url: '/identity/change-password',
    method: 'post',
    data: {
      oldPassword,
      newPassword
    }
  },
  meta: {
    asPromise: true
  }
});

export const passwordRecovery = ({ email }) => ({
  type: actionTypes.RECOVER_PASSWORD,
  request: {
    url: '/identity/PasswordReset/request-reset-password',
    method: 'post',
    data: {
      email,
      app: 'influexer',
      redirectTo: '/password-recovery?token={{token}}&email={{email}}'
    }
  },
  meta: {
    asPromise: true
  }
});

export const passwordRecoverySetNew = ({ token, email, newPassword }) => ({
  type: actionTypes.RECOVER_PASSWORD_SET_NEW,
  request: {
    url: '/identity/PasswordReset/set-new-password',
    method: 'post',
    data: { token, email, newPassword }
  },
  meta: {
    asPromise: true
  }
});

export const resendActivation = ({ email }) => ({
  type: actionTypes.RESEND_ACTIVATION,
  request: {
    url: '/resend-confirm-email',
    method: 'post',
    data: {
      email
    }
  },
  meta: {
    asPromise: true
  }
});

export const checkVersionRequest = () => ({
  type: actionTypes.CHECK_VERSION_REQUEST,
  request: {
    url: `/version.txt?${Date.now()}`,
    baseURL: '',
    method: 'get'
  },
  meta: { asPromise: true }
});

export const checkVersion = () => {
  return {
    type: actionTypes.CHECK_VERSION
  };
};

export const setVersionOutdated = (value) => {
  return {
    type: actionTypes.VERSION_SET_OUTDATED,
    value
  };
};

export const updateToken = () => {
  return {
    type: actionTypes.UPDATE_TOKEN
  };
};

export const updateAvatarUrl = () => {
  return {
    type: actionTypes.UPDATE_AVATAR_URL
  };
};

export const setFilter = (value, initial, oldValue) => {
  return {
    type: actionTypes.SET_FILTER,
    value,
    initial,
    oldValue
  };
};

export const uploadAvatar = ({ fileKey }) => ({
  type: actionTypes.UPLOAD_AVATAR,
  request: {
    url: '/files/FileStorage/UploadAvatar',
    method: 'post',
    data: {
      fileKey
    }
  },
  meta: {
    asPromise: true
  }
});

export const deleteAvatar = () => ({
  type: actionTypes.DELETE_AVATAR,
  request: {
    url: '/files/FileStorage/UploadAvatar',
    method: 'delete'
  },
  meta: {
    asPromise: true
  }
});

export const getProfileDetails = () => ({
  type: actionTypes.GET_PROFILE_DETAILS,
  request: {
    url: '/identity/UserDetails',
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});
