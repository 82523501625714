import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* updateUISettingsSaga(action) {
  const { data = {} } = action;
  const { settings = {} } = data || {};
  const { theme = 'light', layout = 'topmenu' } = settings || {};
  // let contentWidth = 'Fixed';
  // if (layout !== 'topmenu') contentWidth = 'Fluid';
  yield put(
    actions.changeUiSettings({
      navTheme: theme,
      layout
      // contentWidth
    })
  );
}
