import * as actionTypes from '../actions/actionTypes';

export const importGenericDocument = (payload) => ({
  type: actionTypes.PROCESSING_IMPORT_GENERIC_DOCUMENT,
  request: {
    url: `/DocumentProcessing/Processing/ImportGenericDocument`,
    method: 'post',
    data: payload
  },
  meta: {
    asPromise: true
  }
});

export const getSampleImportFile = (guid) => ({
  type: actionTypes.PROCESSING_FETCH_SAMPLE_IMPORT_FILE,
  request: {
    url: `/DocumentProcessing/Processing/GetSampleImportFile/${guid}`,
    method: 'get',
    responseType: 'blob'
  },
  meta: {
    asPromise: true
  }
});

export const generateFile = ({ guid, data }) => ({
  type: actionTypes.PROCESSING_FETCH_SAMPLE_IMPORT_FILE,
  request: {
    url: `/DocumentProcessing/Processing/GenerateFile`,
    method: 'post',
    data: data,
    responseType: 'blob'
  },
  meta: {}
});
