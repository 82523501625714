import * as actionTypes from '../actions/actionTypes';

export const fetchMenu = () => ({
  type: actionTypes.FETCH_MENU,
  request: {
    url: `/identity/ProgramConfig`,
    method: 'get'
  },
  meta: {
    // meta is optional, it will be added to success, error or abort action when defined
  }
});

export const fetchDynamicPage = (pageId, queryString, payload) => {
  return {
    type: actionTypes.FETCH_DYNAMIC_PAGE,
    data: {
      pageId,
      queryString,
      payload
    }
  };
};

export const fetchDynamicPageRequest = (
  pageId,
  queryString,
  payload = false,
  onlyHeader = false
) => {
  const meta = { pageId: pageId, onlyHeader };
  let url = `/domain/WebPage/${pageId}`;
  let method = 'get';
  let data;

  if (queryString) {
    meta.queryString = queryString;
    url += queryString;
    const queryStringParams = new URLSearchParams(queryString);
    const objectId = queryStringParams.get('objectId');
    meta.objectId = objectId;
  }

  if (payload) {
    method = 'post';
    data = payload;
  }

  return {
    type: actionTypes.FETCH_DYNAMIC_PAGE_REQUEST,
    request: {
      url: url,
      method,
      data
    },
    meta: meta
  };
};

export const clearDynamicSession = (pageId, objectId) => {
  const meta = { pageId: pageId };
  if (objectId) {
    meta.objectId = objectId;
  }

  return {
    type: actionTypes.CLEAR_DYNAMIC_SESSION,
    meta: meta
  };
};

export const clearRedirect = (pageId, objectId) => {
  const meta = { pageId: pageId };
  if (objectId) {
    meta.objectId = objectId;
  }

  return {
    type: actionTypes.CLEAR_DYNAMIC_REDIRECT,
    meta: meta
  };
};
