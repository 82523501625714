import { put, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import * as actions from '../actions/index';

export function* tableModalDispatcher(action) {
  const { filterFormKeys, payload, filterFormId, ...rest } = action.payload;
  let formPayload;
  if (filterFormKeys) {
    const { formMeta, ...data } = yield select(getFormValues(filterFormId));
    Object.keys(filterFormKeys).forEach((formKey) => {
      const formValue = filterFormKeys[formKey];
      console.log(formValue);
      if (data[formValue]) {
        if (!formPayload) formPayload = {};
        formPayload[formKey] = data[formValue];
      }
    });
  }

  const newPayload = payload
    ? formPayload
      ? { ...payload, ...formPayload }
      : payload
    : formPayload
    ? formPayload
    : undefined;

  yield put(actions.openModal({ ...rest, payload: newPayload }));
}

export function* tableRequestDispatcher(action) {
  let topFilterData;
  if (action.data.sessionId) {
    const { formMeta, ...data } =
      (yield select(getFormValues(`${action.data.pageId}-topform`))) || {};
    topFilterData = data;
  }
  yield put(
    actions.fetchTableRecordsRequest({ ...action.data, topFilterData })
  );
}
