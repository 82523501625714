import * as actionTypes from './actionTypes';

export const toggleLayout = (settings) => {
  return {
    type: actionTypes.TOGGLE_UI_LAYOUT
  };
};
export const changeUiSettings = (settings) => {
  return {
    type: actionTypes.UPDATE_UI_SETTINGS,
    settings,
    overwrite: false
  };
};

export const overwriteUiSettings = (settings) => {
  return {
    type: actionTypes.UPDATE_UI_SETTINGS,
    settings,
    overwrite: true
  };
};

export const reloadUserSettings = () => {
  return {
    type: actionTypes.RELOAD_USER_SETTINGS
  };
};

export const resetUserSettings = () => {
  return {
    type: actionTypes.RESET_USER_SETTINGS
  };
};

export const updateUserSettings = (settings) => {
  return {
    type: actionTypes.UPDATE_USER_SETTINGS,
    settings
  };
};
