import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { reducer as idleReducer } from '../components/idle';

// import { requestsReducer } from 'redux-saga-requests';
// import { ReduxStore as UppyRedux } from 'uppy';
import UppyRedux from '@uppy/store-redux';

import authReducer, { initialState } from './auth';
import uiReducer from './ui';
import searchReducer from './search';
import dynamicReducer from './dynamic';
import modalReducer from './modal';
import taskReducer from './tasks';
import storiesReducer from './stories';
import menuReducer from './menu';
import { formEngineReducer, reduxFormExtension } from './formEngine';
import reduceReducers from 'reduce-reducers';

const extendedFormReducer = reduceReducers(formReducer, reduxFormExtension);

const fullReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    form: extendedFormReducer,
    menu: menuReducer,
    dynamic: dynamicReducer,
    formEngine: formEngineReducer,
    uppy: UppyRedux.reducer,
    modal: modalReducer,
    ui: uiReducer,
    search: searchReducer,
    idle: idleReducer,
    tasks: taskReducer,
    stories: storiesReducer
  });

  return (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
      state = {
        router: { ...state.router },
        auth: { ...initialState, outdated: state.auth.outdated },
        ui: { ...state.ui }
      };
    }

    return appReducer(state, action);
  };
};
export default fullReducer;
