import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const initialState = {
  token: null,
  refreshToken: null,
  versionTimestamp: process.env.REACT_APP_GIT_DATE || Date.now(),
  serverVersionTimestamp: process.env.REACT_APP_GIT_DATE || Date.now(),
  outdated: false,
  user: {
    auth_time: null,
    avatarUrl: null,
    client_id: null,
    email: null,
    exp: null,
    iss: null,
    jti: null,
    locale: null,
    name: null,
    nbf: null,
    profile: null,
    programId: null,
    roleId: null,
    sub: null,
    username: null
  },
  error: null,
  programs: [],
  loading: false,
  // filter: null,
  authRedirectPath: {
    pathname: '/',
    search: '',
    hash: ''
  }
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    refreshToken: action.refreshToken,
    user: {
      ...action.user,
      avatarUrl: `/v1/files${action.user.avatar}`
    },
    // filter: null,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const updateAvatarUrl = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      avatarUrl: `/public-assets/avatars/${state.user.sub}?${Date.now()}`
    }
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    user: initialState.user,
    filter: null,
    refreshToken: null
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path || {
      pathname: '/',
      search: '',
      hash: ''
    }
  });
};

const setFilter = (state, action) => {
  // return updateObject(state, { filter: action.value });
  return;
};

const updateServerVersion = (state, action) => {
  return updateObject(state, {
    serverVersionTimestamp: action.data
  });
};
const setOutdated = (state, action) => {
  return updateObject(state, {
    outdated: action.value
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
    case actionTypes.AUTH_RESTORE:
    case actionTypes.AUTH_REFRESH:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case success(actionTypes.CHECK_VERSION_REQUEST):
      return updateServerVersion(state, action);
    case actionTypes.VERSION_SET_OUTDATED:
      return setOutdated(state, action);
    case actionTypes.SET_FILTER:
      return setFilter(state, action);
    case actionTypes.UPDATE_AVATAR_URL:
      return updateAvatarUrl(state, action);
    case success(actionTypes.GET_ALLOWED_PROGRAMS):
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default reducer;
