import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { checkVersion } from './store/actions/index';
import { ConfigProvider } from 'antd';
import plPL from 'antd/es/locale/pl_PL';
const AntLayout = React.lazy(() => import('./containers/AntLayout'));

const loading = () => <div className="animated fadeIn pt-3 text-center" />;

class LayoutSelector extends PureComponent {
  componentDidMount() {
    this.checkFocus(); // init

    window.addEventListener('focus', this.checkFocus);
    window.addEventListener('blur', this.checkFocus);
  }

  checkFocus = () => {
    const focus = document.hasFocus();
    if (focus) this.props.checkVersion();
  };

  componentWillUnmount() {
    window.removeEventListener('focus', this.checkFocus);
    window.removeEventListener('blur', this.checkFocus);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Datus Influencer</title>
        </Helmet>
        <React.Suspense fallback={loading()}>
          <ConfigProvider locale={plPL}>
            <AntLayout
              selectedMenuKey={this.props.match.params.selectedMenuKey}
            />
          </ConfigProvider>
        </React.Suspense>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { checkVersion: () => dispatch(checkVersion()) };
};

export default connect(null, mapDispatchToProps)(LayoutSelector);
